<template>
<div class="deposit-container">
<ConfitmModal v-if="modal" @close="closeModal" :data.sync="data" :type="state.userInfo.towStepAuthenticationType"/>
    <p class="title">
        برداشت تومانی
    </p>
    <div class="info-container">
        <div class="coin-info">
            <div>
                <p class="label">
                    انتخاب شماره کارت
                </p>
                <div class="selected-container" @click="drop = !drop">
                    <span v-if="!selectedCard.cardNumber"> انتخاب کارت </span>
                    <span>{{selectedCard.cardNumber}}</span>
                    <img class="dropIcon" src="@/assets/dropIcon.svg" alt="">
                    <div class="drop-down" v-if="drop">
                        <template v-for="(card,index) in state.userInfo.bankAccounts">
                        <div @click="selectCard(card)" v-if="card.status == 'APPROVED'" :key="index">
                            <span> {{card.cardNumber}} </span>
                        </div>
                        </template>
                    </div>
                </div>
            </div>
            <div>
                <p class="label">
                    مقدار برداشتی
                </p>
                <input @input="data.amount = $toLocal(data.amount)" class="address-input" type="text" v-model="data.amount">
            </div>
            <p class="cash">
                قابل برداشت : {{$toLocal(balance)}} تومان
            </p>
            <button :disabled="!selectedCard.cardNumber || !data.amount" @click.prevent="modal=true" class="normal-btn">
                درخواست برداشت
            </button>
        </div>
        <div class="guide-container">
            <div>
                راهنمای برداشت
            </div>
            <div class="warning-container">
                <img src="@/assets/warning.svg" alt="">
                <span>
                    تنها از کارت های عضو شتاب که در پروفایل شما تایید شده اند می توانید واریز انجام دهید
                </span>
            </div>
            <div @click="$router.push({name:'Account'})" class="limit-container">
                <span>
                    <img src="@/assets/plus.svg" alt="">
                    <span>افزودن شماره کارت</span>
                </span>
            </div>
        </div>
    </div>
    <div class="history-container">
        <p>
            تاریخچه برداشت تومانی
        </p>
        <div class="table-contaner">
            <span v-if="tableContents.length < 1" class="no-content">
                محتوایی جهت نمایش وجود ندارد
            </span>
            <perfect-scrollbar v-else @ps-y-reach-end="getWithDrawal">
            <table>
                <tr class="tr-head">
                    <td class="address">
                        زمان
                    </td>
                    <td >
                        مقدار درخواستی
                    </td>
                    <td class="address">
                        شماره کارت
                    </td>
                    <td >
                        وضعیت
                    </td>
                    <td class="address">
                        شناسه پیگیزی
                    </td>
                </tr>
                <tr class="tr-body" v-for="item , index in 7" :key="index">
                    <td class="address">
                        ۰۰/۲/۲ ۱۲:۲۳
                    </td>
                    <td>
                        500000
                    </td>
                    <td class="address">
                        101900000087987987
                    </td>
                    <td class="success">
                        موفق
                    </td>
                    <td class="address">
                        87987987
                    </td>
                </tr>
            </table>
            </perfect-scrollbar>
        </div>
    </div>
</div>
</template>

<script>
import ConfitmModal from './ConfirmModal.vue'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
export default {
    name: 'RialWithdrawal',
    components:{
        ConfitmModal,
        PerfectScrollbar
    },
    data() {
        return {
            modal:false,
            amount:'',
            drop: false,
            selectedCard:{},
            balance:'',
            data:{
                amount:'',
                destinationWalletAddress:'',
                relatedCoin:'TOMAN',
                code:'',
            },
            tableContents:[],
            page:0,
            lastPage:1,
        }
    },
    methods: {
        async getWithDrawal(){
                if (this.page < this.lastPage) {
                    this.page++ ;
                    const res = await this.$axios.get('/wallets/withdrawal-requests', {
                        params: {
                            size: 10,
                            page: this.page
                        }
                    })
                    if (res) {
                        this.lastPage = res.totalPages
                        let content = res.content.filter(a => a.relatedCoin == 'TOMAN')
                        this.tableContents = [...this.tableContents, ...content] //for scroll loading
                        // this.tableContents = res.content //for pagination
                    }
            }
            },
        closeModal(){
            this.modal=false
            this.data={
                amount:'',
                relatedCoin:'TOMAN',
                code:'',
            }
            this.getBalance();
        },
        selectCard(e){
            this.drop=false
            this.selectedCard=e
            this.data.destinationWalletAddress=e.cardNumber
        },
        async getBalance(){
                    this.state.loading=true
                    const res = await this.$axios.get('/wallets/customer-addresses?coins=TOMAN')
                    let entireRes = Object.entries(res)
                    this.balance = entireRes[0][1].credit
        },
    },
    mounted() {
        this.getBalance();
        this.getWithDrawal();
    },
    created:function (){
        window.addEventListener("click",event => {
            if(!event.target.closest('.selected-container')){
                this.drop=false
            }
        })
    }

}
</script>

<style lang="scss" scoped>
.deposit-container{
    min-height: 100vh
}
@import '../../../Styles/Wallet.scss' ;
</style>
